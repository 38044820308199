<template>
  <v-container fluid ma-0 pa-0>
    <v-layout row wrap>
      <v-flex xs12 id="quoter">
        <quoter-car company="gnp" />
        <how-it-works />

        <v-layout v-if="$vuetify.breakpoint.smAndUp" max-width-1400 full-width ma-auto pa-3>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>

        <payment-methods class="px-3" />

        <benefits-gnp />

        <coverages-cars class="px-2" />

        <reviews class="px-2 light-background" />

        <v-layout wrap>
          <v-flex xs12 text-xs-center primary py-5 px-3>
            <p class="graphik-bold white--text" :class="{'font-32': $vuetify.breakpoint.smAndUp, 'font-25': $vuetify.breakpoint.xsOnly}">{{ $ml.get('landing_car_quote') }}</p>
            <p class="graphik-light white--text" :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}">{{ $ml.get('landing_car_description') }}</p>
            
            <v-btn 
              color="pantene"
              depressed dark large
              :loading="g_isLoading"
              class="normalcase graphik-bold-italic ma-0 border-radius-5"
              @click="$vuetify.goTo('#quoter')"
            >{{ $ml.get('general_quote') }}</v-btn>
          </v-flex>
        </v-layout>

        <p class="graphik-medium text-xs-center secondary--text my-3 px-3" :class="{'font-28': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_title') }}</p>

        <p class="graphik-light text-xs-center my-3 px-3" :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-16': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_description') }}</p>

        <products class="px-3 my-5" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ProductCar',
  components: {
    'quoter-car': () => import(/* webpackPrefetch: true */ '../components/QuoterCar'),
    'how-it-works': () => import(/* webpackPrefetch: true */ '../components/ContentHowItWorks'),
    'payment-methods': () => import(/* webpackPrefetch: true */ '../components/ContentPaymentMethods'),
    'benefits-gnp': () => import(/* webpackPrefetch: true */ '../components/ContentBenefitsGNP'),
    'coverages-cars': () => import(/* webpackPrefetch: true */ '../components/ContentCoveragesCars'),
    'reviews': () => import(/* webpackPrefetch: true */ '../components/ContentReviews'),
    'products': () => import(/* webpackPrefetch: true */ '../components/ContentProducts')
  },
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_GNP,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_CAR
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_GNP
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_CAR
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_GNP
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_CAR
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/auto'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/auto'
      }
    ]
  }
}
</script>

<style scoped>
</style>
